import React from "react"

export const renderTags = (locale, defaultLocale, nodes) => {
  if (!nodes) {
    return null
  }

  function metaTag(node) {
    if (node.type === "name") {
      return <meta key={node.id} name={node.typeValue} content={node.content} />
    } else if (node.type === "property") {
      return (
        <meta key={node.id} property={node.typeValue} content={node.content} />
      )
    }
    return null
  }

  function scriptTag(node) {
    const attrs = {}

    if (node.id) {
      attrs.key = node.id
    }

    if (node.type) {
      attrs.type = node.type
    }

    if (node.source) {
      attrs.src = node.source
    }

    let type = "script"

    if (node.tagType && node.tagType === "noscript") {
      type = "noscript"
    }

    switch (type) {
      case "noscript":
        return (
          <noscript
            {...attrs}
            dangerouslySetInnerHTML={{
              __html: node.body && node.body.body ? node.body.body : null
            }}
          ></noscript>
        )
      default:
        return (
          <script {...attrs}>
            {node.body && node.body.body ? node.body.body : null}
          </script>
        )
    }
  }

  return nodes.map(node => {
    switch (node.__typename) {
      case "ContentfulMetaTag":
        return metaTag(node)
      case "ContentfulScriptTag":
        return scriptTag(node)
      default:
        return null
    }
  })
}
