import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import styles from "./NotFound.module.scss"
import Link from "../Link/Link"
import { Container } from "react-bootstrap"
import MarkdownTitle from "../MarkdownTitle"

const NotFound = props => {
  const {
    type,
    image,
    title,
    titleA,
    titleB,
    message,
    ctaTitle,
    ctaURL,
    locale,
    defaultLocale,
    linkHome
  } = props

  const renderButton = link => {
    const props = {
      link: { ...link },
      locale,
      defaultLocale
    }

    return (
      <div className={styles.link_button}>
        <Link {...props} />
      </div>
    )
  }

  return (
    <>
      {type !== "new" ? (
        <div className={styles.notFound}>
          {image && <GatsbyImage image={image.gatsbyImageData} alt="" />}
          <h1 className={[styles.title, "h2"].join(" ")}>
            <MarkdownTitle text={title} />
          </h1>
          <p>{message}</p>
          <a href={ctaURL}>{ctaTitle}</a>
        </div>
      ) : (
        <Container
          fluid
          className={styles.notFoundNew}
          style={{
            backgroundImage: `url(${image.gatsbyImageData.images.fallback.src})`
          }}
        >
          <div className={styles.notFoundWrapper}>
            <div className={styles.notFoundBox}>
              <h1 className={[styles.titleA, "h2"].join(" ")}>{titleA}</h1>
              <span className={styles.text404}>404</span>
              <h1 className={[styles.title, "h2"].join(" ")}>{titleB}</h1>
              <p>{message}</p>
              <div className={styles.linkWrapper}>{renderButton(linkHome)}</div>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

NotFound.propTypes = {
  image: PropTypes.object,
  image404: PropTypes.object,
  title: PropTypes.string,
  titleA: PropTypes.string.isRequired,
  titleB: PropTypes.string.isRequired,
  message: PropTypes.string,
  ctaTitle: PropTypes.string,
  ctaURL: PropTypes.string,
  type: PropTypes.string,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
  linkHome: PropTypes.object
}

export default NotFound
